@media screen and (min-width: 600px) {
    .table-wrap {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
        user-select: none;

        margin: 0 auto;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 35px 0px rgba(94, 85, 126, 0.15);
        border-radius: 6px;

        table {
            width: 100%;
            text-align: right;
            margin-bottom: 36px;
            border-collapse: collapse;
            // thead
            .table-head {
                font-size: 16px;
                font-weight: 500;
                color: rgba(164, 167, 190, 1);
                line-height: 70px;
                position: relative;
                tr {
                    border-top: none;
                    overflow: hidden;
                    td {
                        position: relative;
                        font-weight: normal;
                        font-family: "Roboto", "PingFangSC-Regular",
                            "Microsoft Yahei", "sans-serif";
                    }
                    color: #a4a7be;
                    font-weight: normal;
                    .tdH3 {
                        text-align: left;
                        font-size: 20px;
                        font-weight: bold;
                        color: rgba(67, 73, 118, 1);
                        padding-left: 25px;
                    }
                    &:hover {
                        background-color: #fff;
                        cursor: inherit;
                        &::after {
                            width: 0;
                            height: 0;
                            display: none;
                        }
                    }
                }
            }

            .table-head:hover {
                &::after {
                    display: none;
                }
            }

            .table-head:hover {
                background-color: #fff;
                cursor: initial;
            }
            // *** tr ***
            tr {
                display: flex;
                font-size: 18px;
                color: rgba(67, 73, 118, 1);
                line-height: 90px;
                position: relative;
                border-top: 1px solid #eaeaea;
                overflow: hidden;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: #fcfcfe;
                    cursor: pointer;
                }
            }
        }
    }

    .exchange-table {
        table {
            td {
                flex: 1;

                &:first-child {
                    flex: 2 !important;
                    text-align: left;
                    padding-left: 25px;
                    font-weight: 500;
                    font-family: "Oswald";
                }

                &:nth-child(2) {
                    text-align: left;
                    flex: 1 !important;
                }

                &:nth-child(3) {
                    text-align: left;
                    flex: 1 !important;
                }
                &:nth-child(4) {
                    text-align: left;
                    flex: 1 !important;
                }

                &:last-child {
                    text-align: center;
                    flex: 1.2 !important;
                    padding-right: 15px;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .exchange-table {
        width: 100%;
        margin-bottom: 36px !important;
        .table-wrap {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -khtml-user-select: none;
            user-select: none;

            margin: 0 auto;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 35px 0px rgba(94, 85, 126, 0.15);
            border-radius: 6px;

            table {
                width: 100%;
                text-align: right;
                border-collapse: collapse;
                overflow: hidden;
                border-radius: 6px;
                // thead
                .table-head {
                    font-size: 16px;
                    font-weight: 500;
                    color: rgba(164, 167, 190, 1);
                    line-height: 70px;
                    position: relative;
                    tr {
                        td {
                            position: relative;
                            font-weight: normal;
                            font-family: "Roboto", "PingFangSC-Regular",
                                "Microsoft Yahei", "sans-serif";
                        }
                        color: #a4a7be;
                        font-weight: normal;
                        border-top: none;
                        overflow: hidden;
                        .tdH3 {
                            text-align: left;
                            font-size: 20px;
                            font-weight: bold;
                            color: rgba(67, 73, 118, 1);
                            padding-left: 25px;
                        }
                        &:hover {
                            background-color: #fff;
                            cursor: inherit;
                            &::after {
                                width: 0;
                                height: 0;
                                display: none;
                            }
                        }
                    }
                }

                .table-head:hover {
                    &::after {
                        display: none;
                    }
                }

                .table-head:hover {
                    background-color: #fff;
                    cursor: initial;
                }
                // *** tr ***
                tr {
                    display: flex;
                    font-size: 18px;
                    color: rgba(67, 73, 118, 1);
                    line-height: 90px;
                    position: relative;
                    background: #fff;
                    border-top: 1px solid #eaeaea;
                    overflow: hidden;

                    &:last-child {
                        border-bottom: none;
                    }

                    &:hover {
                        background-color: #fcfcfe;
                        cursor: pointer;
                    }
                }

                // *** td ***
                td {
                    flex: 1;
                    padding: 25px 5px;
                    text-align: center;
                    font-size: 18px;

                    &:first-child {
                        flex: 1.2 !important;
                        text-align: left;
                        font-weight: 500;
                        padding-left: 16px;
                        font-family: "Oswald";
                    }

                    &:nth-child(2) {
                        flex: 1 !important;
                    }

                    &:nth-child(3) {
                        flex: 1 !important;
                    }

                    &:nth-child(4) {
                        flex: 1 !important;
                    }

                    &:last-child {
                        flex: 1.6 !important;
                    }

                    button {
                        margin-top: 6px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
