.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 38px;
    width: 1120px;
    height: 124px;
    transform: translateX(-123px);
    max-width: 1120px;
    padding-top: 26px;

    &__menu {
        display: inline-flex;
        align-items: center;

        label {
            cursor: pointer;
        }

        &_item {
            font-size: 16px;
            color: #252152;
            font-weight: 500;
            margin-right: 20px;
            display: inline-flex;
            align-items: center;
        }

        img {
            width: 11px;
            margin-left: 5px;
            margin-top: 2px;
        }

        &_wallet {
            color: #fff;
            width: 118px;
            height: 30px;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            background: #434976;
            border-radius: 4px;
            // position: relative;
            display: inline-flex;
            // text-align: center;
            align-items: center;
            justify-content: center;

            div {
                width: 80%;
                display: inline-block;
                align-items: center;
                justify-content: space-around;
            }

            i {
                // display: inline-flex;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                // position: absolute;
                // left: 0;
            }

            &:hover {
                color: #fff;
            }
        }
    }
}

.header__menu_wallet {
    border: 1px solid #ffffff;
    border-radius: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff !important;
    background: transparent;
    padding: 7px 12px;
    width: 148px;
    margin-right: 8px;

    button {
        color: #ffffff !important;
        padding: 0;
    }
}

.header__menu_wallet_sm {
    border: 1px solid #ffffff;
    border-radius: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff !important;
    background: transparent;
    padding: 4px 8px;
    width: 128px;
    margin-right: 8px;
    height: 28px;
    text-align: center;
}
